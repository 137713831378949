import React from "react";
import {
  FaGitlab,
  FaGithub,
  FaLinkedinIn,
} from "react-icons/fa";
import DownloadResume from "./DownloadResume"
import profile from "../../../images/profile.jpg";


const socials = [
  {
    id: 2,
    icon: <FaGitlab />,
    link: "https://gitlab.com/DylanZeigler",
  },
  {
    id: 2,
    icon: <FaGithub />,
    link: "https://github.com/DylanZeigler",
  },
  {
    id: 3,
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/dylan-zeigler/",
  }
];


const Sidebar = () => {
  return (
    <aside className="sticky top-0 bg-white md:mx-8 lg:mx-4 mb-8 p-6 shadow-md rounded-md -mt-40">
      <div className="w-64 h-64 rounded-md overflow-hidden mx-auto mb-5">
        <img src={profile} alt="dylanzeigler" className="w-full" />
      </div>
      <div className="text-center">
        <h1 className="text-xl text-gray-800 font-bold mb-1">Dylan Zeigler</h1>
        <p className="text-sm text-gray-400 mb-3">
          Denver, CO
        </p>
        <p className="text-sm text-gray-400 mb-3">
          Machine Learning Engineer at <br/>
          <a href="https://lighty.ai/" className="text-blue-600 pl-1">
            Lighty AI
          </a>
        </p>
        <DownloadResume/>
        <ul className="flex flex-wrap justify-center">
          {socials.map((social, id) => (
            <SocialIcon social={social} key={id} />
          ))}
        </ul>
      </div>
      <div className="text-start pt-4">
        <h3 className="text-md mb-2 uppercase font-medium text-gray-800">
          About Me
        </h3>
        <p className="text-gray-500 text-sm font-light leading-relaxed">
          Developer who has numerous experiences building, scaling, and deploying
          not only software products, but teams as well. Everything from assembly and FPGAs
          to web development and data ETLs, I have a vast array of exposure to
          all parts of the tech stack. Currently, my focus is full stack development and 
          data engineering to support AI/ML use cases. With my agile mindset and technical 
          abilities, I can help you meet your goals while advocating for a positive team
          culture.
        </p>
      </div>
    </aside>
  );
};

export default Sidebar;

const SocialIcon = (props) => {
  const { icon, link } = props.social;
  return (
    <li className="m-2">
      <a
        href={link}
        className="w-8 h-8 bg-blue-100 rounded text-blue-800 flex items-center justify-center hover:text-white hover:bg-blue-600"
      >
        {icon}
      </a>
    </li>
  );
};
